body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#page-container {
  position: relative;
}

#canvas-container {
  z-index: 0;
  position: fixed;
  height: 100%;
  width: 100%;
}

#outlet-container {
  z-index: 2;
  position: relative;
}

.no-click {
  pointer-events: none;
}

.no-click Button, .no-click input, .no-click .hover-animate {
  pointer-events: all;
}

main {
  padding: 0 !important;
}

.title-text {
  font-size: 80px;
}

#nav-item {
  border-radius: 5px;

  padding-left: 9px;
  padding-right: 9px;
  padding-top: 8px;
  padding-bottom: 8px;

  margin-left: 5px;

  text-decoration: none;
}

.nav-item-dark {
  color: whitesmoke;
}

.nav-item-light {
  color: black;
}

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
 @keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-top {
	animation: slide-in-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) 0s both;
}

.slide-in-top-delayed {
  animation: slide-in-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) 0.3s both;
}

.hover-animate:hover {
  transform: scale(1.02);
  transition: transform 0.1s ease-in-out;
}

.MuiDialog {
  max-width: 80%;
}